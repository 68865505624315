import React from 'react'
import { Navbar, Nav, NavDropdown, Dropdown, NavItem, Button } from "react-bootstrap"
import { Link, StaticQuery, graphql } from 'gatsby'
import Logo from './logo'
import { FaUser } from 'react-icons/fa'
import { getUser, logout, isAuthenticated, isAdmin } from '../utils/auth'

function renderItem(item, nItem, location) {
    const active = item.link === (location && location.pathname)
    if (item.children == null) {
      return (
          <Nav.Item key={nItem} className={active ? "active": ""}>
              <Nav.Link as={Link} to={item.link}>
                {item.name} 
              </Nav.Link>
          </Nav.Item>
      )
    }
    else {
      return (
        <NavDropdown key={'dropdown-' + nItem} title={item.name}>
            {item.children.map((subItem, i) => (
                <NavDropdown.Item key={'dropdown' + i} as={Link} to={subItem.link} className={active ? "active": ""}>
                    {subItem.name} 
                </NavDropdown.Item>
            ))}
        </NavDropdown>
      )
    }
  }


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
        as="a"
        variant="white"
        href=""
        ref={ref}
        onClick={e => {
        e.preventDefault();
        onClick(e);
        }}
    >
        <FaUser color="white" size="1.3rem"/>
    </Button>
));
function profile() {
    return (
            
        isAuthenticated() ? (
            <Dropdown as={NavItem} className="ml-2 my-auto">
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu alignRight="false" className="bg-green-light">
                    <p className="px-3 pt-2 h5 text-center">{getUser()['https://lesenfantsdelarbrevert.fr/full_name']}</p>
                    <NavDropdown.Divider />
                    {
                        isAdmin() ? (
                        <NavDropdown.Item as={Link} to="/app/admin">
                            Admin
                        </NavDropdown.Item>
                        ) : ''
                    }
                    <NavDropdown.Item onClick={() => {logout()}}>
                       Se déconnecter
                    </NavDropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        ) : (''
           /*  <Form className="ml-2 my-auto" inline>
                <Button as="span" variant="white" title="S'inscrire/Se connecter">
                    <FaSignInAlt color="white" size="1.3rem" onClick={() => {login()}}/>
                </Button>
            </Form> */
        )
    )
}

export default ({location}) => {
    return (
        <StaticQuery
            query={graphql`
                query MenuLinks {
                    site {
                        siteMetadata {
                            menuLinks {
                                name
                                link
                                children {
                                    name
                                    link
                                }
                            }
                        }
                    }
                }
            `}
            render={data => (
                <Navbar sticky="top" expand="lg" className="navbar-dark bg-green-dark">
                    <Link className="navbar-item" to="/">
                        <Navbar.Brand as="span">
                            <Logo height="3rem"/>
                        </Navbar.Brand>
                    </Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            {
                                data.site.siteMetadata.menuLinks.map((link, i) => (renderItem(link, i, location)))
                            }
                            {
                                profile()
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

        )}
        />
    )
}
