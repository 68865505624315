import React from "react"
import NavBar from "./navBar"
import Footer from "./footer"
import { Container } from 'react-bootstrap';


const Layout = ({ children, location }) => {
  return (
    <>
      <NavBar location={location} />
      <Container fluid className="px-0 main">
          {children}
      </Container>
      <Footer />
    </>
  )
}

export default Layout
